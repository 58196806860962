import { useState, useEffect, useRef, useContext } from 'react'
import { styled } from '@mui/system'
import { ConferenceContext } from 'contexts/conference'
import { ConferenceParticipant } from 'contexts/conference/types'
import { useRenderVideo } from './useRenderVideo'
import { useLayoutUpdate } from 'components/useLayoutUpdate'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'
import { BookingContext } from 'contexts/booking'
import { useFeatureCheck } from 'hooks/useFeatureCheck'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SpeakerIndicatorStaticIcon, HardMuteIcon, MicActiveIcon, MuteLightIcon, StudentAwayIcon, WhiteCloseIcon } from 'icons'
import { useLocation } from 'react-router-dom'
import ZoomIcon from '../../../pages/teacher/classroom/components/ZoomIcon.svg'
import EditIcon from '../../../pages/teacher/classroom/components/editIcon.svg'
import AddIcon from '../../../pages/teacher/classroom/components/addThumbnail.svg'
import { CueCard } from 'api/classroom/ClassroomState'
import { ExpelModal } from 'components/dialog/ExpelDialog'

const Tile = styled('div')<{
    showVideo: boolean
    isFullFeaturedMode: boolean
    forceTileBorders?: boolean
    borderColor: string
}>(({ theme, showVideo, isFullFeaturedMode, forceTileBorders, borderColor }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundColor: showVideo ? 'transparent' : theme.palette.neutral.darker,
    borderStyle: 'solid',
    borderColor: isFullFeaturedMode || forceTileBorders ? borderColor : 'transparent',
    borderWidth: isFullFeaturedMode || forceTileBorders ? theme.spacing(0.75) : 0,
    borderRadius: isFullFeaturedMode ? theme.spacing(2) : 0,
    height: '100%',
    width: '100%',
    marginTop: -1,
    position: 'relative',
    overflow: 'hidden',
    willChange: 'background-color',
    transition: 'background-color 0.2s ease-out'
}))
const NameLabel = styled('span')<{ isHover: boolean }>(({ theme, isHover }) => ({
    fontSize: `clamp(${theme.spacing(1.25)} , calc(var(--tile-fontsize) / 2), ${theme.spacing(3)})`,
    lineHeight: `calc(clamp(${theme.spacing(1.25)} , calc(var(--tile-fontsize) / 2), ${theme.spacing(3)}) + 4px)`,
    fontWeight: '600',
    padding: `${theme.spacing(0.3)} ${theme.spacing(1)}`,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    backgroundColor: isHover ? '#000000f2' : 'rgba(0,0,0,0.65)',
    color: theme.palette.neutral.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: isHover ? 'pointer' : 'unset'
}))
const Avatar = styled('div')(({ theme }) => ({
    fontSize: 'var(--tile-fontsize)',
    fontWeight: 800,
    height: '50%',
    maxHeight: theme.spacing(30),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main,
    margin: 'auto'
}))

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxWidth: 'calc(100% - 40px)'
}))

const NameWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}))

const MoreMenuWrapper = styled('span')<{ isSpeakMode: boolean }>(({ theme, isSpeakMode }) => ({
    margin: '0 5px',
    bottom: isSpeakMode ? '5px' : '3px',
    position: 'relative'
}))

const AudioControls = styled('div')<{ isSpeakMode: boolean }>(({ theme, isSpeakMode }) => ({
    width: '100%',
    height: theme.spacing(5),
    marginBottom: isSpeakMode ? '38px' : theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    position: 'absolute'
}))

const ExtraLargeIcon = styled('img')<{ isSpeakMode: boolean }>(({ theme, isSpeakMode }) => ({
    height: isSpeakMode ? theme.spacing(5) : theme.spacing(3),
    width: isSpeakMode ? theme.spacing(5) : theme.spacing(3),
    marginLeft: theme.spacing(2)
}))

const CustomMenu = styled(Menu)(({ theme }) => ({
    top: '-4px',
    '& .MuiMenu-paper': {
        position: 'absolute',
        zIndex: 100,
        left: 120,
        top: 0,
        textAlign: 'left',
        padding: '8px',
        color: theme.palette.neutral.main,
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        borderRadius: theme.spacing(2),
        borderBottomLeftRadius: '4px',
        boxShadow: '0 30px 60px 0 rgba(0, 0, 0, 0.08), 0 2px 5px 0 rgba(0, 0, 0, 0.02)',
        width: 'max-content'
    },
    '& .MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0
    }
}))

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: '9px 8px',
    img: {
        marginRight: '8px'
    },
    span: {
        fontSize: `clamp(${theme.spacing(1.25)} , calc(var(--tile-fontsize) / 2), ${theme.spacing(3)})`,
        lineHeight: '1.4',
        letterSpacing: '0.32px'
    },
    '&:nth-of-type(2)': {
        borderTop: '1px solid rgba(255, 255, 255, 0.15)'
    }
}))
const StudentAwayWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    height: '40%',
    bottom: '30%',
    maxHeight: theme.spacing(30),
    aspectRatio: '1',
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.80)',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
        maxWidth: '60%'
    }
}))

const CueCardTile = styled('div')(() => ({
    width: '35%',
    height: '35%',
    bottom: '8px',
    right: '8px',
    borderRadius: '16px',
    boxShadow: '0 7px 20px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.02)',
    position: 'absolute',
    backgroundColor: '#F1F1F1'
}))

const CueCardImageTile = styled('img')(() => ({
    position: 'absolute',
    borderRadius: '16px'
}))

const AddCueCardThumbnail = styled('img')(() => ({
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    borderRadius: '72px',
    padding: '12px',
    zIndex: 100,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
}))

const IconWrapper = styled('img')(() => ({
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    borderRadius: '72px',
    padding: '12px',
    zIndex: 100,
    cursor: 'pointer'
}))

const IconWrapperTile = styled('div')(() => ({
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}))

type ParticipantTileProps = {
    participant: ConferenceParticipant
    id: string
    isTeacher?: boolean
    breakouts?: BreakoutRoomsState
    forceTileBorders?: boolean
    isCueCards?: boolean
    cueCardZoomModal?: (data: string) => void
    showCueCardSharingModal?: () => void
}

const ParticipantTile: React.FC<ParticipantTileProps> = (
    { participant, id, isTeacher = false, breakouts = { isActive: false }, forceTileBorders, isCueCards, cueCardZoomModal, showCueCardSharingModal }) => {
    const location = useLocation()
    const isSpeakMode = location.pathname === '/teacher/classroom'
    const booking = useContext(BookingContext)
    const conference = useContext(ConferenceContext)
    const { isFullFeaturedMode } = useFeatureCheck()
    const isActiveSpeaker = conference.speakers.has(participant.clientUserId)
    const isCurrentUserTeacher = booking.status === 'validated' && booking.user.role === 'teacher'
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [isMenuVisible, setMenuVisible] = useState(false)
    const isMicHardMutedForThatParticipant = conference.participantHardMuteStatus.find(student => student.id === participant.wseUserId)?.micHardMuted
    const [isMicHardMuted, setMicHardMuted] = useState(isMicHardMutedForThatParticipant)
    const [isHovered, setIsHovered] = useState(false)
    const isStudentAway = conference.studentsAwayStatus.find(student => student.id === participant.wseUserId)?.isAway
    const stopVideoRender = (isTeacher && !isFullFeaturedMode && breakouts?.isActive)
    const isStudentDistractedStatus = isCurrentUserTeacher && isStudentAway
    const RemoveStudentDisable = process.env.REACT_APP_ENV !== 'production'

    const [isRemoveWrapperOpen, setRemoveWrapperOpen] = useState(false)

    const handleRemoveWrapperOpen = (): void => {
        setRemoveWrapperOpen(true)
        setAnchorEl(null)
        setMenuVisible(false)
    }

    const handleRemoveWrapperClose = (): void => {
        setRemoveWrapperOpen(false)
        setAnchorEl(null)
        setMenuVisible(false)
    }
    const handleRemoveConfirm = (): void => {
        if (conference.status === 'session-joined' && isCurrentUserTeacher) {
            conference.primus.send('user:expel', { id: participant.wseUserId })
        }
    }
    const tileRef = useRef<HTMLDivElement | null>(null)
    const [showVideo, setVideoVisibility] = useState<boolean>(true)
    useRenderVideo(participant, tileRef.current, stopVideoRender)

    const showTile = (): void => setVideoVisibility(true)
    const hideTile = (): void => setVideoVisibility(false)
    const cueCardForStudent = conference.status === 'session-joined' && booking.status === 'validated' && booking.user.role === 'teacher' && conference.cueCard

    const isViewCueCard = cueCardForStudent ? cueCardForStudent.find((data: { cueCard: CueCard }) => data.cueCard) : false
    const isCueCardActive = isCueCards && isViewCueCard

    const showCueCardImage = (participantId: string): string | undefined => {
        const cueCardImage = cueCardForStudent ? cueCardForStudent.find(data => data.id === participantId) : null
        return cueCardImage?.cueCard?.imageURL
    }

    const showCueCard = showCueCardImage(participant.wseUserId)
    const {
        addLayoutUpdateStartListener,
        addLayoutUpdateEndListener,
        removeLayoutUpdateStartListener,
        removeLayoutUpdateEndListener
    } = useLayoutUpdate()

    useEffect(() => {
        if (tileRef.current) {
            addLayoutUpdateStartListener(hideTile)
            addLayoutUpdateEndListener(showTile)
            return () => {
                removeLayoutUpdateStartListener(hideTile)
                removeLayoutUpdateEndListener(showTile)
            }
        }
    }, [])

    const handleMouseEnter = (): void => {
        setMenuVisible(true)
    }

    const handleMouseLeave = (): void => {
        setMenuVisible(false)
        setAnchorEl(null)
    }
    const mouseEnterHandler = (): void => {
        setIsHovered(true)
    }

    const mouseLeaveHandler = (): void => {
        setIsHovered(false)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
    }

    const hardMuteAStudent = (): void => {
        if (isCurrentUserTeacher && participant.role === 'student') {
            if (conference.status === 'session-joined') {
                setMicHardMuted(!isMicHardMuted)
                void conference.rtcSession.updateParticipantsHardMuteStatus(conference.participantHardMuteStatus, participant.wseUserId, !isMicHardMuted)
                conference.primus.send('remote:mute-mic', { status: !isMicHardMuted, uid: participant.wseUserId })
            }
            setAnchorEl(null)
            setMenuVisible(false)
        }
    }

    const handleClose = (): void => {
        setAnchorEl(null)
        setMenuVisible(false)
    }

    const handleZoomIcon = (): void => {
        showCueCard && cueCardZoomModal?.(showCueCard)
    }

    const handleIconClick = (): void => {
        showCueCardSharingModal?.()
    }

    return (
        <Tile id={id} ref={tileRef}
            showVideo={showVideo && (participant.cameraStatus === 'on' && !stopVideoRender)}
            isFullFeaturedMode={isFullFeaturedMode}
            forceTileBorders={forceTileBorders}
            borderColor={(isActiveSpeaker) ? '#64cfe9' : 'white'}
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
        >
            {(participant.cameraStatus !== 'on' || stopVideoRender) ? (
                <Avatar id='participant-avatar' data-testid='student-avatar' sx={{
                    backgroundImage: participant.wseProfilePhotoUrl
                        ? `url('${getSameOriginPhotoUrl(participant.wseProfilePhotoUrl)}')` : 'none'
                }}>
                    {!participant.wseProfilePhotoUrl ? (
                        <span id='participant-initials'>{getDisplayName(participant, 'initials')}</span>
                    ) : null}
                </Avatar>
            ) : null}

            {isCurrentUserTeacher && !(breakouts.isActive && !isSpeakMode)
                ? <AudioControls isSpeakMode={isSpeakMode}>
                    {isActiveSpeaker ? <ExtraLargeIcon src={SpeakerIndicatorStaticIcon} isSpeakMode={isSpeakMode} />
                        : isMicHardMuted ? <ExtraLargeIcon src={HardMuteIcon} isSpeakMode={isSpeakMode} /> : null}
                </AudioControls>
                : null
            }

            <Wrapper>
                <NameLabel data-testid='student-name' id={participant.wseUserId} onClick={handleClick}
                    isHover={isCurrentUserTeacher && isMenuVisible}>
                    <NameWrapper>
                        <span>
                            {getDisplayName(
                                participant,
                                participant.role === 'teacher' ? 'teacher-name' : 'student-name',
                                isCurrentUserTeacher
                            )}
                        </span>
                        { isCurrentUserTeacher && isMenuVisible && participant.role === 'student'
                            ? <MoreMenuWrapper data-testid='more-dot' isSpeakMode={isSpeakMode}>...</MoreMenuWrapper> : null
                        }
                    </NameWrapper>
                </NameLabel>
            </Wrapper>
            {isCurrentUserTeacher && participant.role === 'student' && isMenuVisible
                ? <CustomMenu data-testid='custom-menu'
                    id='participant-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    container={anchorEl}
                >
                    <CustomMenuItem onClick={() => hardMuteAStudent()}>
                        {!isMicHardMuted
                            ? <><img src={MuteLightIcon} /><span>Mute Student</span></>
                            : <><img src={MicActiveIcon} /><span>Unmute Student</span></>
                        }
                    </CustomMenuItem>
                    {RemoveStudentDisable && (
                        <CustomMenuItem data-testid='remove-student'
                            id='student-removed-item'
                            onClick={handleRemoveWrapperOpen}
                        >
                            <img src={WhiteCloseIcon} alt='Remove-from-lesson' />
                            <span>Remove from lesson</span>
                        </CustomMenuItem>)}
                </CustomMenu>
                : null
            }
            {isRemoveWrapperOpen && (
                <ExpelModal
                    isOpen={isRemoveWrapperOpen}
                    onClose={handleRemoveWrapperClose}
                    onRemoveStudent={handleRemoveConfirm}
                    participant={participant}
                />
            )}
            {
                <div
                    onMouseEnter={mouseEnterHandler}
                    onMouseLeave={mouseLeaveHandler}
                >
                    {isCueCardActive ? (
                        <CueCardTile data-testid='cue-card-tile'>
                            {showCueCard ? (
                                <>
                                    <CueCardImageTile src={showCueCard} alt='cue card image' />
                                    {(isHovered) && (
                                        <IconWrapperTile>
                                            <IconWrapper
                                                src={EditIcon}
                                                alt='Edit Icon'
                                                sx={{
                                                    marginRight: '24px'
                                                }}
                                                onClick={handleIconClick}
                                            />
                                            <IconWrapper
                                                src={ZoomIcon}
                                                alt='Zoom Icon'
                                                onClick={handleZoomIcon}
                                            />
                                        </IconWrapperTile>
                                    )}
                                </>
                            ) : (
                                <AddCueCardThumbnail src={AddIcon} alt='add cue card' onClick={handleIconClick} />
                            )}
                        </CueCardTile>
                    ) : null}
                </div>
            }
            {isStudentDistractedStatus ? (
                <StudentAwayWrapper>
                    <img
                        id='student-Away-Icon'
                        src={StudentAwayIcon}
                        alt='studentsAwayIcon'
                    />
                </StudentAwayWrapper>
            ) : null}
        </Tile>
    )
}

export { ParticipantTile }
